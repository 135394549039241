import React from "react"
import FeaturesListItem from "./featuresListItem"
import useTranslations from "../utils/useTranslations"

import checkImg from "../images/components/featuresList/check.svg"

import laptopWeb from "../images/homepage/ui-web.svg"

import speed1 from "../images/products/speed-1.png"
import speed12x from "../images/products/speed-1@2x.png"
import speed1Webp from "../images/products/speed-1.webp"
import speed1Webp2x from "../images/products/speed-1@2x.webp"
import speed2 from "../images/products/speed-2.png"
import speed22x from "../images/products/speed-2@2x.png"
import speed2Webp from "../images/products/speed-2.webp"
import speed2Webp2x from "../images/products/speed-2@2x.webp"




const FeaturesList = (props) => {

	const text = props.text
	const t = useTranslations(text)
	const pageName = props.pageName

	const productsWinBittorrentClassicFree = <div className="row m-auto">
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header" >{t("Download torrents in bulk")}</span>
		  		<p className="feature-list-description mt-3">{t("µTorrent Classic enables simultaneous torrent downloads that you can manage in a single location.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header" >{t("Optimize your bandwidth")}</span>
		  		<p className="feature-list-description mt-3">{t("µTorrent Classic auto-adjusts bandwidth usage, based on your network and internet connection, to ensure fast downloads.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header" >{t("Schedule your torrents")}</span>
		  		<p className="feature-list-description mt-3">{t("Set the day and time for your torrent downloads to start, stop, or when you want to seed. ")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header" >{t("Remotely connect to µTorrent Classic")}</span>
		  		<p className="feature-list-description mt-3">{t("Add torrents to µTorrent Classic remotely, either from µTorrent Android or any web browser. ")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header" >{t("Auto-shutdown the app when done")}</span>
		  		<p className="feature-list-description mt-3">{t("Quit µTorrent Classic or reboot or shut down your computer when your torrents complete.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header" >{t("Customize the interface")}</span>
		  		<p className="feature-list-description mt-3">{t("Setup µTorrent Classic with your preferred display options and how it behaves on your desktop.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header" >{t("Download from RSS")}</span>
		  		<p className="feature-list-description mt-3">{t("Setup µTorrent to automatically download from RSS, either all titles in the feed or specific ones.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header" >{t("Supports Windows XP and up")}</span>
		  		<p className="feature-list-description mt-3">{t("µTorrent Classic is the best Windows 10 torrent client but also supports XP, Vista, and Windows 7 and 8.")}</p>
		  	</FeaturesListItem>
		  	</div>

	const productsWinBittorrentClassicPro = <div className="row m-auto">
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("No more viruses or malware")}</span>
		  		<p className="feature-list-description mt-3" >{t("Built in anti-virus ensures that your PC torrent downloads are safe and secure.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("Play downloaded files instantly")}</span>
		  		<p className="feature-list-description mt-3" >{t("Play torrents and torrent magnet links while they download in an integrated HD media player.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("Convert files to play on mobile")}</span>
		  		<p className="feature-list-description mt-3" >{t("µTorrent Pro helps you convert torrent files after downloading to play on another device.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("Go ads-free!")}</span>
		  		<p className="feature-list-description mt-3" >{t("Enjoy a cleaner interface from the #1 Windows 10 torrent client!")}</p>
		  	</FeaturesListItem>
		  	</div>


	const productsWinBittorrentWebFree = <div className="row m-auto">
		  	<FeaturesListItem img={checkImg} >
		  		<span className="feature-list-header" >{t("Play torrent files near-instantly")}</span>
		  		<p className="feature-list-description mt-3" >{t("Play both torrent files and magnet links while they download in a high-quality media player.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg} >
		  		<span className="feature-list-header" >{t("Identify safe torrents")}</span>
		  		<p className="feature-list-description mt-3" >{t("A safe torrent scanner helps you download torrents more safely when searching Google, Yahoo or Bing.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg} >
		  		<span className="feature-list-header" >{t("Easy, intuitive interface")}</span>
		  		<p className="feature-list-description mt-3" >{t("The web torrent client installs in your favorite web browser and makes torrent downloads simple.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg} >
		  		<span className="feature-list-header" >{t("Download any file")}</span>
		  		<p className="feature-list-description mt-3" >{t("µTorrent Web is ideal for video, but you can download any type of file, big or small.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg} >
		  		<span className="feature-list-header" >{t("Save network resources")}</span>
		  		<p className="feature-list-description mt-3" >{t("Set limits on download and upload speeds to free up bandwidth for other household internet tasks.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg} >
		  		<span className="feature-list-header" >{t("Localized in 15 languages")}</span>
		  		<p className="feature-list-description mt-3" >{t("µTorrent Web is available in several languages including Spanish, Italian, Korean, Russian, Portuguese, French and more!")}</p>
		  	</FeaturesListItem>
		  	</div>


	const productsWinBittorrentWebMac = <div className="row m-auto">
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("Play torrent files near-instantly")}</span>
		  		<p className="feature-list-description mt-3" >{t("Play both torrent files and torrent magnet links while they download in a high-quality media player.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("Browser-based interface")}</span>
		  		<p className="feature-list-description mt-3" >{t("The Mac torrent client installs directly inside your favorite web browser and is simple to use.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("Download any type of file")}</span>
		  		<p className="feature-list-description mt-3" >{t("µTorrent Web for Mac is ideal for fast audio and video playback, but you can download any type of file.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("Identify safe torrents")}</span>
		  		<p className="feature-list-description mt-3" >{t("A safe torrent scanner helps you download torrents more safely when searching Google, Yahoo or Bing.")}</p>
		  	</FeaturesListItem>
		  	</div>
		  	
	const productsWinBittorrentWebPro = <div className="row m-auto">
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("Ad-Free")}</span>
		  		<p className="feature-list-description mt-3" >{t("Enjoy an uninterrupted torrenting experience with our ad-free interface, included in the Pro Version. Focus on your favorite content without any distractions.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("Block Threats")}</span>
		  		<p className="feature-list-description mt-3" >{t("Pro has added security to keep your torrent PC downloads safe. Get real-time insights into potential virus risks in your torrent files through our tightly integrated in-client AV scanning.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem colSizing="col-md-6 " img={checkImg}>
		  		<span className="feature-list-header" >{t("Premium Customer Support")}</span>
		  		<p className="feature-list-description mt-3" >{t("Pro allows you access to our dedicated premium customer services. Our support team is committed to prioritizing your needs and providing effective solutions.")}</p>
		  	</FeaturesListItem>
		  	</div>

	const productsAndroidBittorrentFree = <div className="row m-auto">
			<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header">{t("Fast and powerful")}</span>
		  		<p className="feature-list-description mt-3" >{t("Download torrents at high speeds using the BitTorrent P2P protocol.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header">{t("The µTorrent portable app!")}</span>
		  		<p className="feature-list-description mt-3" >{t("Download and play torrents while you’re on the go and away from your desktop.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header">{t("Download multiple files")}</span>
		  		<p className="feature-list-description mt-3" >{t("Download torrents in bulk from your phone or tablet.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg} id="android-free-features-btremote-learn-more">
		  		<span className="feature-list-header">{t("Save data with wifi-only mode")}</span>
		  		<p className="feature-list-description mt-3" >{t("Lock your connection to local wifi networks instead of consuming your mobile data plan.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg} learnMore={t('Learn more')} to="/remote/">
		  		<span className="feature-list-header">{t("Add torrents to µTorrent Classic")}</span>
		  		<p className="feature-list-description mt-3" >{t("Remotely add torrents to µTorrent Classic on Windows from your mobile device.")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header">{t("Playback files")}</span>
		  		<p className="feature-list-description mt-3" >{t("Get superior music and video playback with an integrated media player. ")}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg}>
		  		<span className="feature-list-header">{t("Save network resources")}</span>
		  		<p className="feature-list-description mt-3" >{t("Set download/upload speed limits to free up network resources for your other non-torrent mobile tasks.")}</p>
		  	</FeaturesListItem>
		  	</div>

	const homepage = <div className="row m-auto">
		  	
		  	<FeaturesListItem img={checkImg} alt="Download torrents in bulk">
		  		<span className="feature-list-header" >{t(`Download torrents in bulk`)}</span>
		  		<p className="feature-list-description mt-3" >{t(`µTorrent Classic enables simultaneous torrent downloads that you can manage in a single location.`)}</p>	
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg} alt="Optimize your bandwidth">
		  		<span className="feature-list-header" >{t(`Optimize your bandwidth`)}</span>
		  		<p className="feature-list-description mt-3" >{t(`µTorrent Classic auto-adjusts bandwidth usage, based on your network and internet connection, to ensure fast downloads.`)}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg} alt="Schedule your downloads">
		  		<span className="feature-list-header" >{t(`Schedule your downloads`)}</span>
		  		<p className="feature-list-description mt-3" >{t(`Set the day and time for your torrent downloads to start, stop or seed.`)}</p>
		  	</FeaturesListItem>
		  	<FeaturesListItem img={checkImg} alt="Remotely connect to µTorrent Classic">
		  		<span className="feature-list-header" >{t(`Remotely connect to µTorrent Classic`)}</span>
		  		<p className="feature-list-description mt-3" >{t(`Add torrents to µTorrent Classic remotely from a web browser on any device.`)}</p>
		  	</FeaturesListItem>
		  	</div>


	switch(pageName){

		case 'desktop':
			return productsWinBittorrentClassicFree

		case 'pro':
			return productsWinBittorrentClassicPro
		
		case 'web':
			return productsWinBittorrentWebFree
		
		case 'web_mac':
			return productsWinBittorrentWebMac
		
		case 'web_pro':
			return productsWinBittorrentWebPro
		
		case 'mobile':
			return productsAndroidBittorrentFree
		
		default:
			return homepage
	}

}

export default FeaturesList
