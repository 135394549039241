import React from "react"
import arrowBlack from "../images/icons/arrow-black.svg"
import LocalizedLink from "./localizedLink"

const FeaturesListItem = (props) => {

	let learnMoreEl = <>
		<LocalizedLink 
			id={props.id}
			className="text-dark text-decoration-none feature-list-learn-more" 
			to={props.to}>

		 {props.learnMore} 

		</LocalizedLink>
		<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
	</>

	if(props.to){
		if(props.to[0] !== "/"){
			learnMoreEl = <>
				<a
					id={props.id}
					className="text-dark text-decoration-none feature-list-learn-more" 
					href={props.to}
					target="_blank"
					rel="noopener noreferrer">

				 {props.learnMore} 

				</a>
				<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
			</>
		}
	}


	let link = ( typeof props.to !== "undefined" ) ? learnMoreEl : ""
	let img = ( typeof props.img !== "undefined" ) ? <div className="feature-list-img"><img src={props.img} alt={props.alt} className="img-fluid"/></div> : ""
	let colSizing = ( typeof props.colSizing !== "undefined" ) ? props.colSizing : "col-md-6" 

	return(
		<div className={`my-4 ${colSizing} py-0 pl-0 pl-lg-5 d-inline-block position-relative text-center text-md-left`}>
			<div className="feature-list-content text-left">
				<div className="mt-1 flex-shrink-0">
					{img}
				</div>
				<div className="pl-4 pr-4 pr-lg-0">
					<h3 className="">{props.children[0]}</h3>
					{props.children[1]}
					{link}
				</div>
			</div>
		</div>
	)
}


export default FeaturesListItem

